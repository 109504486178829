import { get, post } from './utils/helper.js';

const getCreateChallenge = async () => {
	return await get('/passkey/create');
};

const getLoginChallenge = async () => {
	return await get('/passkey/login');
};

const postCreateChallenge = async (credential) => {
	return await post('/passkey/create', credential);
};

const postLoginChallenge = async (credential) => {
	return await post('/passkey/login', credential);
};
const postNoPromptPasskey = async () => {
	return await post('/passkey/no_prompt');
};

export {
	postCreateChallenge,
	getLoginChallenge,
	postLoginChallenge,
	getCreateChallenge,
	postNoPromptPasskey
};
