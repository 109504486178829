/**
 * @author Valentin Hervieu
 * https://codesandbox.io/s/y09komm059?file=/src/canvasUtils.js
 */

import { readAndCompressImage } from 'browser-image-resizer';
import imageCompression from 'browser-image-compression';

const createImage = (url) =>
	new Promise((resolve, reject) => {
		const image = new Image();
		image.addEventListener('load', () => resolve(image));
		image.addEventListener('error', (error) => reject(error));
		//  image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
		image.src = url;
	});

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {String} type - is a banner or picture
 */
export async function getCroppedImg(imageSrc, pixelCrop, type = 'picture') {
	if (!['banner', 'picture'].includes(type)) throw new Error(`Invalid type passed: ${type}`);

	const image = await createImage(imageSrc);
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');

	const maxSize = Math.max(image.width, image.height);

	// Why we are doing this?: https://pqina.nl/blog/canvas-area-exceeds-the-maximum-limit/
	// TLDR: iOS Safari does not support canvas size (w*h) > 16777216
	const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
	const safeArea = clamp(
		2 * ((maxSize / 2) * Math.sqrt(2)),
		0,
		Math.sqrt(16777216) //maximum pixes supported by safari ios (width * height < 16777216)
	);

	// set each dimensions to double largest dimension to allow for a safe area for the
	// image to rotate in without being clipped by canvas context
	canvas.width = safeArea;
	canvas.height = safeArea;

	// translate canvas context to a central location on image to allow rotating around the center.
	ctx.translate(safeArea / 2, safeArea / 2);
	ctx.translate(-safeArea / 2, -safeArea / 2);

	// draw image and store data.
	ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
	const data = ctx.getImageData(0, 0, safeArea, safeArea);

	// set canvas width to final desired crop size - this will clear existing context
	canvas.width = pixelCrop.width;
	canvas.height = pixelCrop.height;

	// paste generated image with correct offsets for x,y crop values.
	ctx.putImageData(
		data,
		Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
		Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
	);

	const config = {
		banner: {
			maxWidth: 1500,
			maxHeight: 500
		},
		picture: {
			maxWidth: 200,
			maxHeight: 200
		}
	};

	const blob = await new Promise((res) => canvas.toBlob((blob) => res(blob)));
	//TODO: handle errors
	const resizedImage = await readAndCompressImage(blob, {
		quality: 1,
		...config[type]
	});

	if (resizedImage.size / 1024 < 1024) return resizedImage;

	//compress image
	const options = {
		maxSizeMb: 1,
		useWebWorker: true
	};

	//TODO: handle errors
	const compressedFile = await imageCompression(resizedImage, options);

	return compressedFile;
}
